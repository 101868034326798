import HeaderItem from '../HeaderItem';

export default function PageFooter() {
    return (
        <div className='flex justify-end px-5 py-4 text-sm'>
            <div className='flex justify-end w-20'>
                <HeaderItem
                    originalText='LINKEDIN'
                    link='https://www.linkedin.com/in/robertjosephwayne'
                    openNewTab={true}
                />
            </div>
            <div className='flex justify-end w-20'>
                <HeaderItem
                    originalText='GITHUB'
                    link='https://github.com/robertjosephwayne'
                    openNewTab={true}
                />
            </div>
        </div>
    );
}
