import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import HeaderItem from '../components/HeaderItem';
import { usePageTracking } from '../analytics';

export default function Portfolio() {
    usePageTracking();

    return (
        <div className='flex flex-col justify-between w-full h-full text-white'>
            <PageHeader />

            <div className='flex flex-col m-auto space-y-12 text-lg font-bold'>
                <HeaderItem
                    originalText='FINANCE BRAIN'
                    link='https://www.financebrain.ai'
                    openNewTab={true}
                />
            </div>

            <PageFooter />
        </div>
    );
}
