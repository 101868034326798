import { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const lettersByLanguage = {
    en: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
};

const startsByLanguage = {
    en: ['1e4', '1t6', '1h0', '3fr', '3yu', '3vw', 'bc1', '0x1', '0x5', '0x7'],
};

export default function HeaderItem(props: {
    originalText: string;
    link?: string;
    openNewTab?: boolean;
}) {
    const [originalText] = useState(props.originalText);
    const [currentText, setCurrentText] = useState(props.originalText);

    function decodeAnimation(el: any, isHover: any, timeEachLetter = 60) {
        const currentLang = 'en';
        const letters = lettersByLanguage[currentLang].split('');
        const start = startsByLanguage[currentLang];
        const firstLetters = start[Math.floor(Math.random() * start.length)];

        function randomString(len: any, text: any, index: any) {
            let string = firstLetters;
            const higherThan = currentLang === 'en' ? 2 : 0;
            for (let i = 0; i < len; i++) {
                if (i > higherThan) {
                    if (text[i] === ' ') {
                        string += ' ';
                    } else {
                        string += letters[Math.floor(Math.random() * letters.length)];
                    }
                }
            }
            return string.slice(index);
        }

        function getText(len: any, text: any) {
            let string = '';
            for (let i = 0; i < len; i++) {
                string += text[i];
            }
            return string;
        }

        function constructText(text: any, index: any) {
            setTimeout(function () {
                const merged = getText(index, text) + randomString(text.length, text, index);
                setCurrentText(merged);
            }, index * timeEachLetter);
        }

        const text = isHover ? originalText : '';
        for (let i = 0, len = text.length; i <= len; i++) {
            constructText(text, i);
        }
    }

    const handleMouseOver = (event: any) => {
        decodeAnimation(event.target, true);
    };

    const options: { target?: string; rel?: string } = {};

    if (props.openNewTab) {
        options.target = '_blank';
        options.rel = 'noopener noreferrer';
    }

    return (
        <div className='flex header'>
            <Link to={props.link || '/'} {...options}>
                <span onMouseOver={handleMouseOver}>{currentText}</span>
            </Link>
        </div>
    );
}
