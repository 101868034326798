import PageFooter from '../components/PageFooter';
import PageHeader from '../components/PageHeader';
import HeaderItem from '../components/HeaderItem';
import { usePageTracking } from '../analytics';

export default function Root() {
    usePageTracking();

    return (
        <div className='flex flex-col justify-between w-full h-full text-white'>
            <PageHeader />

            <div className='flex m-auto text-lg font-bold'>
                <HeaderItem originalText='PORTFOLIO' link='/portfolio' />
            </div>

            <PageFooter />
        </div>
    );
}
